import nextCookie from "next-cookies";
import Link from "next/link";
import Router, { useRouter, } from "next/router";
import cookie from "js-cookie";
import GlobalStyle, { MenuDesktop, MenuMobile, Logo, MasonryChild, MasonryGroup, MasonryChildTitle, MansoryChildIntern, MasonryChildText } from "../styles/newHome";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
// import { Masonry } from 'react-masonry'
import 'bootstrap/dist/css/bootstrap.css';


import 'react-awesome-slider/dist/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {
  Hero,
  Infos,
  Info,
  Highlights,
  HighlightsInfos,
  HighlightsGrid,
  ProductBackground,
  Slider,
  Carrousel
} from "../styles/home";

const infos = [
  {
    icon: "/icons/pagamento.png",
    title: "Pagamento",
    description: "Descontos para pagamento à vista"
  },
  {
    icon: "/icons/transporte.png",
    title: "Transporte",
    description: "Entregamos em todo Brasil"
  },
  {
    icon: "/icons/parcelamento.png",
    title: "Parcelamento",
    description: "Parcele suas compras conforme seu segmento"
  }
];

function Home({  token, cart_token }) {


  return (

    <div>

      <GlobalStyle />

      <MenuDesktop>
        <div class="topnav" style={{
          paddingTop: `10px`,
          paddingBottom: `10px`
        }}>
          <div class="topnav-centered">
            <a href="#home" class="active">
              <img style={{ maxWidth: `160px` }} src="/vectorpaint.svg" alt="Logo Goods Br" /><br />
              <span style={{ fontSize: `13px`, fontFamily: `Poppins, sans-serif`  }} >C O M P A N Y</span>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/goodsbr/" target="blank"><img style={{ minWidth: `20px` }} src="/instagram.svg" alt="Logo Goods Br" /></a>
            <a href="/nossa-historia" style={{ fontFamily: `Poppins, sans-serif`  }}>Nossa História</a>
          </div>
          <div class="topnav-right">
            <a href="#" style={{ fontFamily: `Poppins, sans-serif`  }}>Empreendimentos</a>
            <a href="#" style={{ fontFamily: `Poppins, sans-serif`  }}>Blog</a>
          </div>

        </div>
      </MenuDesktop>

      <MenuMobile>
        <nav role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <li>
                <a style={{ fontFamily: `Poppins, sans-serif`  }} href="/nossa-historia">Nossa História</a>
              </li>
              <li>
                <a  style={{ fontFamily: `Poppins, sans-serif`  }} href="#">Empreendimentos</a>
              </li>
              <li>
                <a style={{ fontFamily: `Poppins, sans-serif`  }} href="#">Blog</a>
              </li>
            </ul>
          </div>
          <div style={{ textAlign: `center` }}>
            <img src="/vectorpaint.svg" alt="Logo Goods Br" style={{ width: `170px` }} />
            <br />
            <span style={{ color: `white` }}>C O M P A N Y</span>
          </div>
        </nav>
      </MenuMobile>
      <MasonryGroup>
        <div style={{ gridRow: `span 2`, height: `50vh` }}>
              <MasonryChild style={{ backgroundImage: `url(/fotos_home/catalogo.jpg)`, cursor: `pointer`, backgroundSize: `cover`, backgroundPosition: `bottom center`, height: `25vh` }}>
            <MansoryChildIntern onClick={() => Router.push('/busca?q=')} >
              <div style={{
                textAlign: `center`,
                display: `flex`,
                alignItems: `center`,
                flexDirection: `column`,
                cursor: `pointer`,
                justifyContent: `center`
              }}>
                <MasonryChildTitle>CATÁLOGO</MasonryChildTitle>
                <MasonryChildText>
                  Fabricamos orgulhosamente produtos em Gramado, nossas coleções<br />
                  estão disponíveis nas melhores lojas do Brasil.
                </MasonryChildText>
              </div>
            </MansoryChildIntern>
          </MasonryChild>
          <MasonryChild style={{ backgroundImage: `url(/fotos_home/nova-foto-loja-online.jpg)`, cursor: `pointer`, backgroundSize: `cover`, backgroundPosition: `center center`, height: `25vh` }}>
            <MansoryChildIntern>
              <div style={{
                textAlign: `center`,
                display: `flex`,
                alignItems: `center`,
                flexDirection: `column`,
                justifyContent: `center`
              }}>
                <MasonryChildTitle>LOJA ONLINE</MasonryChildTitle>
                <MasonryChildText>
                  {/* Fabricamos orgulhosamente produtos em Gramado, nossas coleções<br />
                        estão disponíveis nas melhores lojas do Brasil. */}
                </MasonryChildText>
              </div>
            </MansoryChildIntern>
          </MasonryChild>
        </div>
        <MasonryChild style={{ backgroundImage: `url(/fotos_home/empreendimentos.jpg)`, backgroundSize: `cover`, height: `50vh` }}>
          <MansoryChildIntern onClick={() => Router.push('https://goodsbrempreendimentos.com.br')} style={{ cursor: `pointer`}}>
            <div style={{
              textAlign: `center`,
              display: `flex`,
              alignItems: `center`,
              flexDirection: `column`,
              justifyContent: `center`,            }}>
              <MasonryChildTitle>EMPREENDIMENTOS</MasonryChildTitle>
              <MasonryChildText>
                Construímos para criar espaços onde viver<br />
                com propósito se torna uma realidade.
              </MasonryChildText>
            </div>
          </MansoryChildIntern>
        </MasonryChild>
        <div style={{ gridRow: `span 2`, height: `50vh` }}>
          <MasonryChild style={{ backgroundImage: `url(/fotos_home/conceito.jpg)`, backgroundSize: `cover`, height: `25vh` }}>
            <MansoryChildIntern>
              <div style={{
                textAlign: `center`,
                display: `flex`,
                alignItems: `center`,
                flexDirection: `column`,
                justifyContent: `center`
              }}>
                <MasonryChildTitle>LOJA CONCEITO</MasonryChildTitle>
                <MasonryChildText>
                  Descubra uma experiência incomparável em Gramado! <br />
                  Nossa loja não é apenas um espaço de compras, é um destino em si mesmo.
                  Com a renomada embaixada Jonnie Walker, um café acolhedor, a elegância dos estofados
                  Natuzzi, decoração inspiradora e móveis sob medida de alto padrão.
                </MasonryChildText>
              </div>
            </MansoryChildIntern>
          </MasonryChild>

          <MasonryChild style={{ backgroundImage: `url(/fotos_home/new_mund_goodsbr.jpg)`, backgroundSize: `cover`, height: `25vh` }}>
            <MansoryChildIntern>
              <div style={{
                textAlign: `center`,
                display: `flex`,
                alignItems: `center`,
                flexDirection: `column`,
                justifyContent: `center`
              }}>
                <MasonryChildTitle>MUNDO GOODS BR</MasonryChildTitle>
                <MasonryChildText>
                  Experimento o incrível mundo Goods Br.<br />
                  Leia sobre o que estamos fazendo, sobre a nossa história e tudo o que
                  estamos construindo.
                </MasonryChildText>
              </div>
            </MansoryChildIntern>
          </MasonryChild>
        </div>
      </MasonryGroup>
      <MasonryGroup>
        <MasonryChild style={{ backgroundImage: `url(/fotos_home/cafe.jpg)`, backgroundSize: `cover`, height: `43vh` }}>
          <MansoryChildIntern>
            <div style={{
              textAlign: `center`,
              display: `flex`,
              alignItems: `center`,
              flexDirection: `column`,
              justifyContent: `center`
            }}>
              <MasonryChildTitle>CAFÉ</MasonryChildTitle>
              {/* <MasonryChildText>
                  Experimento o incrível mundo Goods Br.<br/>
                  Leia sobre o que estamos fazendo, sobre a nossa história e tudo o que
                  estamos construindo. 
                </MasonryChildText> */}
            </div>
          </MansoryChildIntern>
        </MasonryChild>
        <MasonryChild style={{ backgroundImage: `url(/fotos_home/interiores.jpg)`, backgroundSize: `cover`, height: `43vh` }}>
          <MansoryChildIntern>
            <div style={{
              textAlign: `center`,
              display: `flex`,
              alignItems: `center`,
              flexDirection: `column`,
              justifyContent: `center`
            }}>
              <MasonryChildTitle>INTERIORES</MasonryChildTitle>
              <MasonryChildText>
                Criamos projetos através das histórias. São mais de 100
                <br />traduções já executadas.
              </MasonryChildText>
            </div>
          </MansoryChildIntern>
        </MasonryChild>
        <MasonryChild style={{ backgroundImage: `url(/fotos_home/natuzzi.jpg)`, backgroundSize: `cover`, height: `43vh` }}>
          <MansoryChildIntern>
            <div style={{
              textAlign: `center`,
              display: `flex`,
              alignItems: `center`,
              flexDirection: `column`,
              justifyContent: `center`
            }}>
              <MasonryChildTitle>NATUZZI GRAMADO</MasonryChildTitle>
              <MasonryChildText>
                Descubra a exclusividade da Natuzzi Editions em Gramado.<br />
                Compre o seu sofá italiano em Gramado, em um ambiente único.

              </MasonryChildText>
            </div>
          </MansoryChildIntern>
        </MasonryChild>

      </MasonryGroup>

      <footer class="text-lg-start bg-body-tertiary text-muted" style={{ backgroundColor: `white` }}>
        <section class="pt-2" style={{ backgroundColor: `white` }}>
          <div class="container text-md-start">
            <div class="row">
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 style={{ color: `#daa520` }} class="text-uppercase fw-bold mb-4">
                  SEDE CORPORATIVA
                </h6>
                <p class="" style={{ color: `black`}}>
                  Av. Primeiro de Maio, 3400, Bairro Várzea Grande - Gramado, RS CEP: 95.678-314.
                </p>
                <p  style={{ color: `black`}}>
                  <a class="linkFooter" href="tel:5432955300">+55 54 3295-5300</a>
                </p>
                <p style={{ color: `black`}}>
                  <a class="linkFooter" href="https://api.whatsapp.com/send?phone=5554996346710">Whatsapp</a>
                </p>
              </div>
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 style={{ color: `#daa520` }} class="text-uppercase fw-bold mb-4">
                  LOJA GOODSBR
                </h6>
                <p class="" style={{ color: `black`}}>
                  Av. Borges de medeiros, 2381, Bairro Centro - Gramado, RS CEP: 95.670-092
                </p>
                <p  style={{ color: `black`}}>
                  <a class="linkFooter" href="tel:5432955300">+55 54 3295-5300</a>
                </p>
                <p style={{ color: `black`}}>
                  <a class="linkFooter" href="https://api.whatsapp.com/send?phone=5554996346710">Whatsapp</a>
                </p>
              </div>
         
            </div>
          </div>
        </section>
        <div style={{ backgroundColor: `black`}}>

        <section class="pt-2" style={{ backgroundColor: `black`, textAlign: `right` }} >
          <div class="container text-center text-md-start" style={{ paddingLeft: `0px`, justifyContent: `initial` }}>
            <div class="" style={{ paddingTop: `10px`, paddingBottom: `10px` }}>
              <a href="#home" class="active" >
                <img style={{ maxWidth: `160px` }} src="/vectorpaint.svg" alt="Logo Goods Br" /><br />
                {/* <span style={{ color: `white`, textDecoration: `none` }} >C O M P A N Y</span> */}
              </a>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: `black`, textAlign: `right` }}>
          <div class="text-left container pb-5" style={{ justifyContent: `initial` }}>
            <a class="pr-2 active text-muted" href="https://www.goodsbr.com.br/termos-condicoes" >Termos e condições</a>
            <div class="">
              COPYRIGHT © 2024. GOODS BR. TODOS OS DIREITOS RESERVADOS.
            </div>
          </div>
        </section>
        </div>

      </footer>

      <script
          dangerouslySetInnerHTML={{
            __html: `    var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?59910';
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = url;
                    var options = {
                  "enabled":true,
                  "chatButtonSetting":{
                      "backgroundColor":"#4dc247",
                      "ctaText":"",
                      "borderRadius":"25",
                      "marginLeft":"0",
                      "marginBottom":"50",
                      "marginRight":"50",
                      "position":"right"
                  },
                  "brandSetting":{
                      "brandName":"Goods Br",
                      "brandSubTitle":"",
                      "brandImg":"/logo_goods_fundo_branco.png",
                      "welcomeText":"Oi, tudo bem? <br>Em que posso lhe ajudar?",
                      "messageText":"Olá eu tenho uma duvida sobre a página {{page_link}}",
                      "backgroundColor":"#0a5f54",
                      "ctaText":"Começar conversa",
                      "borderRadius":"25",
                      "autoShow":false,
                      "phoneNumber":"5554996346710"
                  }
                };
                    s.onload = function() {
                        CreateWhatsappChatWidget(options);
                    };
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);`
          }}
        />
    </div>





    // <Layout headerAbsolute="true" token={token} cart_token={cart_token}>

    //   <Container>

    //     {(!user?.vip) ? (<Slider >
    //       <AutoplaySlider
    //         play={true}
    //         cancelOnInteraction={false}
    //         interval={4000}
    //         bullets={false}
    //       >
    //         {background_images}

    //       </AutoplaySlider>
    //     </Slider>
    //     ) : ""}

    //     <Infos>
    //       {infos.map((info, index) => (
    //         <Info key={index}>
    //           <Icon size="38">
    //             <img src={info.icon} alt="Ícone de Informação" />
    //           </Icon>
    //           <div>
    //             <h2>{info.title}</h2>
    //             <span>{info.description}</span>
    //           </div>
    //         </Info>
    //       ))}
    //     </Infos>

    //     {/* <Link href="https://m.goodsbr.com.br/cadastro-atendimento-virtual">
    //       <a rel="noopener noreferrer" title="Feira">
    //         <LazyLoadImage
    //           effect="blur"
    //           src="/banner-atendimento.png"
    //           alt="Banner"
    //           style={{ marginBottom: "50px", display: "block" }}
    //         />
    //       </a>
    //     </Link> */}

    //     <Highlights>
    //       <HighlightsInfos>
    //         <h2>Destaques Goods Br</h2>
    //         <h3>Produtos em Destaque</h3>
    //         <p>
    //           Confira a seleção de produtos que fizemos especialmente para você!
    //         </p>
    //       </HighlightsInfos>

    //       <HighlightsGrid>
    //         {fetchedHighlights.slice(0, 6).map(highlight => (
    //           <ProductBackground key={highlight.sku}>
    //             <ProductItem product={highlight} cart_token={cart_token} clean />
    //           </ProductBackground>
    //         ))}
    //       </HighlightsGrid>
    //     </Highlights>
    //     {(!user?.vip) ? (
    //       <Link href="/cadastrar">
    //         <a rel="noopener noreferrer" title="Cadastre-se">
    //           <LazyLoadImage
    //             effect="blur"
    //             src="/banner-home.jpg"
    //             alt="Banner"
    //             style={{ margin: "0 auto", display: "block" }}
    //           />
    //         </a>
    //       </Link>) : ""}
    //   </Container>
    // </Layout>
  );
}

Home.getInitialProps = async ctx => {
  const { token, outlet, cart_token } = nextCookie(ctx);


  // const fetchedHomepage = await pagesApi.homepage();
  // const fetchedHighlights = await productsApi.highlights({ user_token: token });

  return {
    token,
    outlet,
    cart_token
  };
};

export default Home;
