import styled, { createGlobalStyle } from "styled-components";


export const MenuDesktop = styled.div`
    font-family: 'Poppins', sans-serif;
    @media screen and (max-width: 768px) {
        display: none;
    }    
`;

export const MenuMobile = styled.div`
    @media screen and (max-width: 768px) {
        display: block;
    }    
    font-family: 'Poppins', sans-serif;
    display: none;
`;
export const MasonryChild = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MansoryChildIntern = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  
  &:hover {
    background-color: rgba(0, 0, 0, 0.62);
    
    p {
      display: block;
      font-size: 12px;

    }
  }
`;


export const MasonryChildTitle = styled.span`
  color: white;
  font-size: 16px;
`;

export const MasonryChildText = styled.p`
  color: white;
  display: none;
`;

export const MasonryGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.34%);
  
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 100%);
  }
`;

export const Logo = styled.div`
max-width: 160px;

`;

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  background: #fff;
  color: #666;
  -webkit-font-smoothing: antialiased !important;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

svg {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}


.topnav {
    position: relative;
    overflow: hidden;
    background-color: white;
  }
  
  .topnav a {
    float: left;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .topnav a:hover {
    color: black;
    font-weight: bold;
  }
  
  .topnav a.active {
    background-color: black;
    color: white;
  }
  
  .topnav-centered a {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .topnav-right {
    float: right;
  }
  
  /* Responsive navigation menu (for mobile devices) */
  @media screen and (max-width: 600px) {
    .topnav a, .topnav-right {
      float: none;
      display: block;
    }
    
    .topnav-centered a {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }


  body {
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: #1E1E23;
    opacity:1;
    font-family: 'work sans', sans serif;
    font-size: 1.5em;
    font-weight: 400;
    transition: 200ms;
  }
  a:hover {
    text-decoration: none;
  }
  ul {
    padding: 0;
    list-style-type: none;
  }
  
  
  
  .container {
    margin-top: 50px; 
    Display: flex;
    justify-content: center;
    align-items: center;
  }
  .phone {
    background-color: #36383F;
    border-radius: 40px;
    width: 300px;
    height: 600px;
    Display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 30px 50px 100px #85888C;
  }
  .content {
    border: 1px solid #36383F;
    border-radius: 20px;
    width: 94%;
    height: 91%;
    background-color: #F5F6FA;
    overflow: hidden;
  }
  nav {
    background-color: black;
    height: 85px;
  }
  
  
  #menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 34px;
    margin-left: 25px;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }
  
  #menuToggle input
  {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  
  #menuToggle span
  {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }
  
  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383F;
  }

  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    transform: rotate(-45deg) translate(0, -1px);
  }
  
  #menu
  {
    position: absolute;
    width: 100%;
    height: 400px;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    background-color: #F5F6FA;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }
  
  #menu li
  {
    padding: 10px 0;
    transition-delay: 2s;
  }
  
  #menuToggle input:checked ~ ul
  {
    transform: none;
  }

  a {
    text-decoration: none;
    opacity:1;
    // color: white; 
    font-family: 'work sans', sans serif;
    font-size: 1.5em;
    transition: 200ms;
  }
  a:hover {
    text-decoration: none;
    color: auto !important;
    font-weight: bold;
  }

  a.linkFooter:hover {
    text-decoration: none;
    color: black !important;
    font-weight: bold;
  }
  `;